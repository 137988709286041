<template>
  <div>
    <div class="text-center">
      <client-only placeholder="Loading...">
        <VPdf
          ref="myPdfComponent"
          class="pdf"
          :src="src"
          :page="1"
          @num-pages="setNumPages"
        />
        <div v-if="numPages">
          <div v-for="i in numPages" :key="i" class="mb-1">
            <VPdf v-if="i > 1" :src="src" :page="i" />
          </div>
        </div>
        <v-btn
          color="primary"
          outlined
          :href="src"
          target="_blank"
          class="mt-6"
        >
          <v-icon left>mdi-file-download-outline</v-icon> Baixar
        </v-btn>
      </client-only>
    </div>
  </div>
</template>

<script>
import VPdf from "vue-pdf";
import ClientOnly from "vue-client-only";

export default {
  components: {
    ClientOnly,
    VPdf
  },
  props: {
    src: {
      type: String,
      default: null,
      required: true
    }
  },
  data() {
    return {
      numPages: undefined
    };
  },
  methods: {
    setNumPages(numPages) {
      this.numPages = numPages;
    }
  }
};
</script>
