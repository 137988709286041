<template>
  <div class="save-button pt-6">
    <div v-if="failed">
      <AlertBox
        message="👆🏾 Você deve corrigir os erros acima antes de continuar"
      />
    </div>
    <div class="d-flex justify-end align-center">
      <v-btn v-if="showBack" text plain color="primary" @click="$emit('back')"
        >Anterior</v-btn
      >
      <v-btn
        v-if="showNext"
        x-large
        type="submit"
        :disabled="disabled || failed || invalid || loading"
        v-bind="btnAttrs"
      >
        <v-progress-circular
          v-if="loading"
          color="primary"
          indeterminate
          size="20"
        />
        <span v-else>{{ label }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import AlertBox from "./AlertBox.vue";
export default {
  components: {
    AlertBox
  },
  props: {
    showNext: {
      type: Boolean,
      default: true
    },
    failed: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Salvar"
    },
    showBack: {
      type: Boolean,
      default: false
    },
    back: {
      type: Function,
      default: null
    },
    btnAttrs: {
      type: Object,
      default: () => {
        return {
          class: "white--text px-12 py-6",
          color: "primary",
          rounded: true
        };
      }
    }
  }
};
</script>
<style lang="scss">
.save-button {
  .v-btn__content {
    font-size: 16px;
  }
}
</style>
<style scoped lang="scss">
@import "../scss/styles-from-borrower-portal.scss";
</style>
